<template>
    <div id="titles">
        <div id="messages">
            <div :class="message.class" v-if="message">
                <a @click="message = null" class="close">[close]</a>
                <p>{{message.text}}</p>
                <div class="clear"></div>
            </div>
        </div>

        <div class="row mt-3 mb-3">
            <div class="col-md-12">
                <h2 class="title float-left mr-3"> My Titles</h2>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12 col-md-6">
                <label class="control-label" >Title</label>
                <input v-model="titleFilter" v-on:input="refreshTitles" type="text" autocomplete="off" class="form-control">
            </div>
            <div class="col-xs-12 col-md-6">
                <label class="control-label" >Author</label>
                <input v-model="authorFilter" v-on:input="refreshTitles" type="text" autocomplete="off" class="form-control">
            </div>
        </div>

        <div>     
            <div class="float-left">
                <template v-if="titlesLoaded">
                    <p style="margin-bottom: 0px; margin-top: 24px; font-weight: 700;">{{titles.length}} title{{titles.length == 1 ? '' : 's'}}</p>
                </template>
            </div>
            <div class="float-right">
                <template v-if="selectedTitles && (selectedTitles.length > 0 || titles.length === 1) && anySelectedNotHidden">
                    <button style="margin-bottom: 10px;" type="button" class="btn btn-success change-status-button" data-toggle="modal" data-target="#updateStatusModal" onclick="this.blur();">
                        Edit / Reconfirm Status
                    </button>
                    <button style="margin-bottom: 10px;" type="button" class="btn btn-danger" data-toggle="modal" data-target="#representModal" onclick="this.blur();">
                        I Don’t Represent
                    </button>
                </template>
                <template v-else>
                    <p style="margin-bottom: 0px; margin-top: 24px;">Please select titles for bulk actions</p>
                </template>
            </div>
        </div>

        <table class="table table-striped table-responsive-sm" >
            <template v-if="titles.length > 0">
                <thead>
                <tr>
                    <th width="200px"
                        :class="sortMethod == 'title' ? 'active arrow' : 'arrow'"
                        @click="sortTitles('title')"
                    >
                        Title
                        <span  v-if="sortDirAsc">&#8593;</span>
                        <span v-else>&#8595</span>
                    </th>
                    <th width="200px"
                        :class="sortMethod == 'authors' ? 'active arrow' : 'arrow'"
                        @click="sortTitles('authors')"
                    >
                        Authors
                        <span  v-if="sortDirAsc">&#8593;</span>
                        <span v-else>&#8595</span>
                    </th>
                    <th width="200px"
                        :class="sortMethod == 'short_story' ? 'active arrow' : 'arrow'"
                        @click="sortTitles('short_story')"
                    >
                        Short Story
                        <span  v-if="sortDirAsc">&#8593;</span>
                        <span v-else>&#8595</span>
                    </th>
                    <th width="200px">Agents</th>
                    <th width="100px"
                        :class="sortMethod == 'rights_status' ? 'active arrow' : 'arrow'"
                        @click="sortTitles('rights_status')"
                    >
                        Status
                        <span  v-if="sortDirAsc">&#8593;</span>
                        <span v-else>&#8595</span>
                    </th>
                    <th width="150px"
                        :class="sortMethod == 'right_status_updated_at' ? 'active arrow' : 'arrow'"
                        @click="sortTitles('right_status_updated_at')"
                    >
                        Last update
                        <span  v-if="sortDirAsc">&#8593;</span>
                        <span v-else>&#8595</span>
                    </th>
                    <th>
                        <input :checked="selectedAll" @click="selectAll()" type="checkbox">
                    </th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="title in filterTitles(titles)">
                    <td class="title"><a :href="'/titles/' + title.attributes.slug">{{title.attributes.title}}</a></td>
                    <td class="">{{title.attributes.authors}}</td>
                    <td>{{title.attributes.short_story}}</td>
                    <td class="" style="">
                        <template v-for="(agent, index) in title.attributes.agents">
                            {{agent}}{{index == 0 ? ', ' : '.'}}
                            <br />
                        </template>
                    </td>
                    <td class="">{{title.attributes.rights_status}}</td>
                    <td class="">{{title.attributes.right_status_updated_at}}</td>
                    <td>
                        <div class="form-check">
                            <input v-if="titles.length > 1" type="checkbox" class="form-check-input" v-model="selectedTitles" :value="title.id">
                        </div>
                    </td>
                </tr>
                </tbody>


            </template>

            <template v-else-if="!titlesLoaded">
                <h4 class="mt-4">Loading titles...</h4>
            </template>
            <template v-else>
                <h4 class="mt-4">Titles not found</h4>
            </template>
        </table>

        <div class="modal my_modal" id="updateStatusModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal_style" role="document">
                <div class="modal-content">
                    <input type="hidden" name="update_title[update_method]" value="update_status">
                    <div class="modal-body">
                        <h4 class="modal-title">Edit / Reconfirm Status</h4>
                        <hr>
                        <p>Please select a new status</p>
                        <div class="form-group">
                            <label class="control-label">Rights status</label>
                            <label style="text-transform: none;"> (*Required)</label>
                            <div v-if="showMissingStatus" style="font-size: 14px; color: red;">
                                Please select the current status when submitting any changes, even if it has not changed since the last update.
                            </div>
                            <select class="form-control" data-style="btn-primary"
                                    v-model="selectedStatus"
                            >
                                <option value=""></option>
                                <template v-for="status in statuses" >
                                    <option :value="status.value" >{{status.name}}</option>
                                </template>
                            </select>
                        </div>
                        <hr>

                        <h4 class="modal-title">Add/Edit Notes</h4>
                        <hr>
                        <p>
                            Please include only information pertaining to the film/tv rights status.
                        </p>
                        <div class="form-group">
                            <textarea v-model="notes" class="form-control" rows="3"></textarea>
                        </div>
                        <div class="form-check">
                            <input v-model="destroy_note" type="checkbox" class="form-check-input" id="destroy_note">
                            <label class="form-check-label" for="destroy_note">Remove existing notes</label>
                        </div>
                        <hr>
                        <div class="float-right">
                            <a type="button" class="dg-btn dg-btn--cancel btn-cancel rights-status-update-cancel" data-dismiss="modal">Cancel</a>
                            <button @click="updateTitles('update_status')" class="btn btn-primary modal_submit rights-status-update-submit">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal my_modal" id="representModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <h4 class="modal-title">Represent</h4>
                        <hr>
                        <p>
                            Please let us know if any applicable co-agent should also be removed from the record, and share any forwarding info if you have for the author.
                        </p>
                        <p>
                            Thank you.
                        </p>
                        <div class="form-group">
                            <textarea v-model="description" class="form-control" rows="3"></textarea>
                        </div>
                        <hr>
                    </div>
                    <div class="modal-footer">
                        <a type="button" class="dg-btn dg-btn--cancel btn-cancel" data-dismiss="modal">Cancel</a>
                        <button class="btn btn-primary modal_submit" @click="updateTitles('represent')">Submit</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                selectedTitles: [],
                titles: [],
                message: null,
                authorFilter: "",
                titleFilter: "",
                sortMethod: null,
                sortDirAsc: true,
                description: '',
                notes: '',
                destroy_note: false,
                selectedStatus: '',
                showMissingStatus: false,
                anySelectedNotHidden: true,
                titlesLoaded: false,
                statuses: [{ name: 'Available', value: 0 },
                           { name: 'Unavailable', value: 1 },
                           { name: 'Produced', value: 2 },
                           { name: 'Public Domain', value: 3 },
                           { name: 'Contact Agent', value: 4 }]
            }
        },
        computed: {
            ids: function () {
                if(this.titles.length === 1) {
                    return this.titles[0].id;
                }
                return this.selectedTitles.join(',');
            },
            selectedAll: function () {
                return this.titles.length === this.selectedTitles.length
            }
        },
        methods: {
            failed(data) {
                this.message = { class: 'error', text: data }
            },
            refreshTitles() {
                this.$forceUpdate();
            },
            sortTitles(sortParam) {
                if (this.sortMethod === sortParam) {
                    this.sortDirAsc = !this.sortDirAsc;
                } else {
                    this.sortDirAsc = true;
                }
                this.sortMethod = sortParam;
                let sortDir = this.sortDirAsc;

                let titles = this.titles;
                titles.sort(function(a, b){
                    let fieldA;
                    let fieldB;
                    if (sortParam === 'right_status_updated_at') {
                        fieldA = a.attributes[sortParam] == null ? '' : new Date(a.attributes[sortParam]);
                        fieldB = b.attributes[sortParam] ==  null ? '' : new Date(b.attributes[sortParam]);
                    } else {
                        fieldA = a.attributes[sortParam] == null ? '' : a.attributes[sortParam].toLowerCase();
                        fieldB = b.attributes[sortParam] ==  null ? '' : b.attributes[sortParam].toLowerCase();
                    }

                    if (fieldA === fieldB) {
                        return 0;
                    }
                    else if (fieldA === '') {
                        return 1;
                    }
                    else if (fieldB === '') {
                        return -1;
                    }
                    else if (sortDir === true) {
                        return fieldA < fieldB ? -1 : 1;
                    }
                    else if (sortDir === false) {
                        return fieldA < fieldB ? 1 : -1;
                    }

                });
                this.titles = titles;
            },
            filterTitles(titles) {
                var filteredTitleList = []
                for (const title of titles) {
                    if (this.titleFilter) {
                        if ( 
                            title.attributes.title.toLowerCase().includes(this.titleFilter)
                            || (title.attributes.subtitle && title.attributes.subtitle.toLowerCase().includes(this.titleFilter))
                            || (title.attributes.short_story && title.attributes.short_story.toLowerCase().includes(this.titleFilter))
                        ) {
                            filteredTitleList.push(title)
                        }
                    }
                    else if (this.authorFilter) {
                        if (title.attributes.authors.toLowerCase().includes(this.authorFilter)) {
                            filteredTitleList.push(title)
                        }
                    } else {
                            filteredTitleList.push(title)
                    }
                }
                this.anySelectedNotHidden = this.getSelectedFromTitles(filteredTitleList).length > 0
                return filteredTitleList
            },
            getTitles() {
                this.$http.get('rights_holder_titles.js')
                    .then(response => {
                        this.titlesLoaded = true;
                        this.titles = response.body.data;
                    })
                    .catch((response) => this.failed(response.body.data));
            },
            updateTitles(method) {
                if (this.selectedStatus === "") {
                    this.showMissingStatus = true;
                } else {
                    this.showMissingStatus = false;
                    let filteredTitles = this.filterTitles(this.titles)
                    let filteredAndSelectedTitles = this.getSelectedFromTitles(filteredTitles)
                    this.$http.patch('/titles/bulk_update.js', { ids: filteredAndSelectedTitles.join(),
                        update_title: { update_method: method, description: this.description, notes: this.notes,
                        destroy_note: this.destroy_note, rights_status: this.selectedStatus }})
                    .then(response => {
                        $('.modal').modal('hide');
                        this.message = { class: 'notice', text: response.bodyText };
                        this.resetParams();
                        this.getTitles();
                    })
                    .catch((response) => this.failed(response.body.data))
                }
            },
            resetParams() {
                this.selectedTitles = [];
                this.selectedStatus = '';
                this.description = '';
                this.notes = '';
                this.destroy_note = false;
            },
            selectAll() {
                if(this.selectedAll) {
                    this.selectedTitles = []
                } else {
                    this.selectedTitles = this.titles.map(title => title.id);
                }
            },
            getSelectedFromTitles(titles) {
                let titlesMap = titles.reduce((acc, val) => {
                    acc[val.id] = true
                    return acc
                }, {})
                return this.selectedTitles.filter((title) => {
                    return title in titlesMap
                })
            },
        },
        created: function () {
            this.getTitles();
        },
        updated: function () {
            $('[data-toggle="tooltip"]').tooltip();
        }
    }

</script>

<template>
    <a v-if="mutable_reminder_id"
       href="javascript: void(0);"
       @click="removeReminder()"
    >
        <img data-toggle="tooltip"
             data-placement="top"
             :data-original-title=" 'Remind at ' +  mutable_remind_at"
             class="remove-icon print-none" src="/remove_date.svg" width="15px">
    </a>

    <datetime v-else
              v-model="date"
              type="date"
              :phrases="{ok: 'Set reminder', cancel: 'Exit'}"
              @input="setReminder()"
              data-title="Set a reminder"
              data-toggle="tooltip"
              data-placement="top"
              data-original-title="Set a reminder"
    >
    </datetime>
</template>

<script>
  export default {
    data: function () {
      return {
        date: '',
        mutable_remind_at: this.remind_at,
        mutable_reminder_id: this.reminder_id,
      }
    },
    props: ['min_reminder_date', 'title_id', 'reminder_id', 'remind_at' ],
    methods: {
        setReminder() {
            if(this.date != '' && this.correctDate()) {
                this.$http.post('/title_reminders.js',
                    { title_reminder: { remind_at: this.date, title_id: this.title_id }} )
                    .then(function (response) {
                        this.mutable_reminder_id = response.body.data.id;
                        this.mutable_remind_at = response.body.data.remind_at;
                    })
                    .catch((response) => this.failed(response.body.data));
                this.date = '';
            } else if(this.date != '') {
                swal("Selected date is invalid", 'Please choose a date in the future.', "error");
                this.date = '';
            }
        },

        correctDate() {
            let d1 = new Date(this.date);
            let d2 = new Date(this.min_reminder_date);
            return d1 >= d2;
        },
        removeReminder() {
            this.$http.delete('/title_reminders/' + this.mutable_reminder_id + '.js')
                .then(response => {
                    $('[data-toggle="tooltip"]').tooltip('hide');
                    this.mutable_reminder_id = null;
                    this.mutable_remind_at = null;
                })
                .catch((response) => this.failed(response.body.data));
        }
    },
    updated: function () {
        $('[data-toggle="tooltip"]').tooltip();
    },
  }

</script>
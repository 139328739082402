<template>
  <div id="lists">
    <div class="row mt-3" :class="shared ? '' : 'mb-3'" >
      <div class="col-md-12">

        <h2 class="title float-left mr-3">{{ title }}</h2>

        <a v-if="public == false" href="/watch_lists/new" class="btn btn-secondary">Add New Watch List</a>

        <div class="float-right buttons-block">
          <template v-if="selectedLists && (selectedLists.length > 0 || watch_lists.length === 1)">
            <div class="export_watch_list_icon">
              <p class="mt-1">Please select lists for export/print</p>
              <a :href="'/watch_lists/watch_lists_export.csv?ids=' + ids" target="_blank" >
                <img src="/csv.svg"
                     title="Export selected lists to CSV"
                     alt="Export to csv file"
                     width="20px"
                     data-toggle="tooltip"
                     data-placement="top"
                     :data-original-title="'Export to CSV'">
              </a>
              <a :href="'/watch_lists/watch_lists_export.pdf?ids=' + ids" target="_blank">
                <img src="/pdf.svg"
                     title="Export selected lists to PDF"
                     alt="Export to pdf file"
                     width="20px"
                     data-toggle="tooltip"
                     data-placement="top"
                     :data-original-title="'Export to PDF'">
              </a>
              <a @click="print" href="javascript:void(0);">
                <img src="/printer.svg"
                     title="Print selected lists"
                     alt="Print selected lists"
                     width="20px"
                     data-toggle="tooltip"
                     data-placement="top"
                     :data-original-title="'Print'">
              </a>
            </div>
          </template>
          <template v-else>
            <p class="mt-1">Please select lists for export/print</p>
          </template>
        </div>
      </div>
    </div>

    <div v-if="shared == true">
      <p>Shared lists that have published or updated since you last logged in:</p>
    </div>

    <div v-if="public == false" class="">
      <div class="checkbox">
        <label class="boolean optional" for="includeSharedLists">
          <input v-model="includeSharedLists" class="boolean optional" type="checkbox" id="includeSharedLists" >
          Include shared lists
        </label>
      </div>
    </div>

    <table class="table table-striped table-responsive-sm" >
      <template v-if="watch_lists.length > 0">
        <thead>
        <tr>
          <th
            width="30px"
            :class="sortMethod == 'share_with_my_company' ? 'active arrow' : 'arrow'"
            @click="sortWatchLists('share_with_my_company')"
          >
            <span  v-if="sortDirAsc">&#8593;</span>
            <span v-else>&#8595</span>
          </th>
          <th width="200px"
              :class="sortMethod == 'name' ? 'active arrow' : 'arrow'"
              @click="sortWatchLists('name')"
          >
            List
            <span  v-if="sortDirAsc">&#8593;</span>
            <span v-else>&#8595</span>
          </th>
          <th width="400px"
              :class="sortMethod == 'description' ? 'active arrow' : 'arrow'"
              @click="sortWatchLists('description')"
          >
            Description
            <span  v-if="sortDirAsc">&#8593;</span>
            <span v-else>&#8595</span>
          </th>
          <th width="120px">Actions</th>
          <th></th>
        </tr>
        </thead>

        <tbody>
        <tr v-for="list in filteredLists">
          <td class="">
            <img v-if="list.attributes.share_with_my_company"
                 src="/users.svg"
                 title="Shared watch list"
                 alt="Shared watch list"
                 width="20px"
                 data-toggle="tooltip"
                 data-placement="top"
                 :data-original-title="'Shared watch list'">

            <img v-if="shared === true && list.attributes.public === true"
                 src="/book-open.svg"
                 title="Rightscenter watch list"
                 alt="Rightscenter watch list"
                 width="20px"
                 data-toggle="tooltip"
                 data-placement="top"
                 :data-original-title="'Rightscenter watch list'">
          </td>
          <td class="title"><a :href="'/' + (list.attributes.public ? 'public_' : '') +  'watch_lists/' + list.id">{{list.attributes.name}}</a></td>
          <td class="">{{list.attributes.description}}</td>

          <template v-if="list.attributes.public && list.attributes.my_list != true">
            <td class="watch_list_delete">

              <a  v-if="list.attributes.in_my_lists === false && list.attributes.my_list === false && list.attributes.user_dbaccess === true"
                  href="javascript: void(0);"
                  @click="addToMyLists(list.id)"
              >
                <img src="plus.svg" alt="" width="20px"
                     data-toggle="tooltip"
                     data-placement="top"
                     data-original-title="Add to My Watch Lists"
                     title="Add to My Watch Lists">
              </a >

              <a
                  @click="removeFromMyLists(list.id)"
                  href="javascript: void(0);"
                  v-if="list.attributes.in_my_lists === true">
                <img src="minus.svg" alt="" width="20px"
                     data-toggle="tooltip"
                     data-placement="top"
                     data-original-title="Remove from My Watch Lists"
                     title="Remove from My Watch Lists">
              </a >

            </td>
          </template>

          <template v-else>
            <td class="watch_list_delete" v-if="list.attributes.my_list || list.attributes.coworkers_editable">

              <a
                  class="mr-2"
                  :href="'/watch_lists/' + list.id + '/edit/'"
              >
                <img src="writing.svg"
                     alt=""
                     width="20px"
                     data-toggle="tooltip"
                     data-placement="top"
                     :data-original-title="'Edit'">
              </a>

              <template v-if="list.attributes.my_list && list.attributes.share_with_my_company">
                <a class="mr-2"
                   @click="setEditable(list.id, false)"
                   v-if="list.attributes.coworkers_editable"
                   href="javascript: void(0);"
                >
                  <img src="lock-key-open.svg"
                       alt=""
                       width="25px"
                       data-toggle="tooltip"
                       data-placement="top"
                       :data-original-title="'Coworkers can edit'">
                </a>

                <a class="mr-2"
                   @click="setEditable(list.id, true)"
                   v-if="list.attributes.coworkers_editable === false"
                   href="javascript: void(0);"
                >
                  <img src="lock-key.svg"
                       alt=""
                       width="25px"
                       data-toggle="tooltip"
                       data-placement="top"
                       :data-original-title="'Coworkers can view only'">
                </a>
              </template>

              <a class=""
                 href="javascript: void(0);"
                 v-confirm="{
                 html: true,
                 ok: dialog => deleteWatchList(list.id),
                 customClass: 'my_modal',
                 cancel: '',
                 okText: 'Delete',
                 cancelText: 'Cancel',
                 message: '<h4>Delete this list?</h4><hr>'
               }"
               v-if="list.attributes.my_list"
              >
                <img src="delete.svg"
                     alt=""
                     width="20px"
                     data-toggle="tooltip"
                     data-placement="top"
                     :data-original-title="'Delete'">
              </a >

            </td>
            <td v-else></td>
          </template>

          <td>
            <div class="form-check">
              <input v-if="filteredLists.length > 1" type="checkbox" class="form-check-input" v-model="selectedLists" :value="list.id">
            </div>
          </td>
        </tr>
        </tbody>


      </template>

      <template v-else>
        <h4 class="mt-4"> {{ public ? title + ' Not Found' : 'You have not created any watch lists yet' }}</h4>
      </template>
    </table>

  </div>
</template>

<script>
  export default {
    data: function () {
      return {
        selectedLists: [],
        watch_lists: [],
        message: null,
        sortMethod: null,
        sortDirAsc: true,
        includeSharedLists: true,
      }
    },
    props: ['public', 'shared'],
    computed: {
      ids: function () {
        if(this.watch_lists.length === 1) {
          return this.watch_lists[0].id;
        }
        return this.selectedLists.join(',');
      },
      filteredLists: function () {
        if(this.includeSharedLists) {
          return this.watch_lists;
        } else {
          return this.watch_lists.filter(function (el) {
            return (el.attributes.share_with_my_company == false);
          });
        }
      },
      title: function () {
        if (this.public) {
          return this.shared ? 'Shared Lists' : 'Rightscenter Lists';
        } else {
          return 'My Watch Lists'
        }
      }
    },
    methods: {
      addToMyLists(id) {
        this.$http.post('/public_watch_lists.js', { id: id })
          .then(response => this.updateItem(response.body.data))
          .catch((response) => this.failed(response.body.data));
      },
      removeFromMyLists(id) {
        this.$http.delete('/public_watch_lists/' + id + '.js')
          .then(response => this.removeItem(response.body.data))
      },
      updateItem(data) {
        let list = data;
        let foundIndex = this.watch_lists.findIndex(x => x.id == list.id);
        this.watch_lists[foundIndex].attributes = list.attributes;
      },
      removeList(id) {
        this.$http.delete('/watch_lists/' + id + '.js')
          .then(response => this.removeItem(response.body.data))
      },
      removeItem(data) {
        if(this.public) {
          this.updateItem(data);
        } else {
          this.watch_lists = this.watch_lists.filter(function (el) {
            return el.id !== data.id;
          });
        }
      },
      failed(data) {
        this.message = { class: 'error', text: data }
      },
      sortWatchLists(sortParam) {
        if (this.sortMethod === sortParam) {
          this.sortDirAsc = !this.sortDirAsc;
        } else {
          this.sortDirAsc = true;
        }
        this.sortMethod = sortParam;
        let sortDir = this.sortDirAsc;

        let watch_lists = this.watch_lists;
        watch_lists.sort(function(a, b){
          let fieldA;
          let fieldB;
          if (typeof a.attributes[sortParam] == "boolean") {
            fieldA = a.attributes[sortParam]
            fieldB = b.attributes[sortParam]
          } else {
            fieldA = a.attributes[sortParam] == null ? '' : a.attributes[sortParam].toLowerCase();
            fieldB = b.attributes[sortParam] ==  null ? '' : b.attributes[sortParam].toLowerCase();
          }
          if (fieldA === fieldB) {
            return 0;
          }
          else if (fieldA === '') {
            return 1;
          }
          else if (fieldB === '') {
            return -1;
          }
          else if (sortDir === true) {
            return fieldA < fieldB ? -1 : 1;
          }
          else if (sortDir === false) {
            return fieldA < fieldB ? 1 : -1;
          }

        });
        this.watch_lists = watch_lists;
      },
      deleteWatchList(id){
        this.$http.delete('/watch_lists/' + id +  '.js')
          .then(response => {
            let lists = this.watch_lists;
            lists = lists.filter(list => list.id !== id);
            this.watch_lists = lists;
          })
          .catch((response) => this.failed(response.body.data));
      },
      getWatchLists() {
        let link = this.public ? '/public_watch_lists.js' : '/watch_lists.js';
        this.$http.get(link)
          .then(response => {
            this.watch_lists = response.body.data;
          })
          .catch((response) => this.failed(response.body.data));
      },
      print() {
        let printWindow = window.open( '/watch_lists/watch_lists_export' + '?ids=' + this.ids, 'Print', 'left=200, top=200, width=950, height=500, toolbar=0, resizable=0');
        printWindow.addEventListener('load', function(){
          printWindow.print();
          // printWindow.close();
        }, true);
      },
      setEditable(id, editable) {
        this.$http.patch('/watch_lists/' + id + '.js', { watch_list: { coworkers_editable: editable } })
                .then(response => this.updateItem(response.body.data));
      }
    },
    created: function () {
      this.getWatchLists();
    },
    updated: function () {
      $('[data-toggle="tooltip"]').tooltip();
    }
  }

</script>
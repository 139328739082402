<template>
  <div>
    <div id="messages">
      <div :class="message.class" v-if="message">
        <a @click="message = null" class="close">[close]</a>
        <p>{{message.text}}</p>
        <div class="clear"></div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="checkbox">
          <label class="boolean optional" for="completed">
            <input v-model="includeCompleted" class="boolean optional" type="checkbox" id="completed" >
            Include completed requests
          </label>
        </div>
      </div>

      <div v-if="shared" class="col-md-4">
        <div class="checkbox">
          <label class="boolean optional" for="completed">
            <input v-model="includeCompanyRequest" class="boolean optional" type="checkbox" id="includeRequest" >
            Include coworkers’ requests
          </label>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table table-striped table-responsive-sm" id="lists status">
        <thead>
        <tr class="t_table_top">
          <th class="id_width"></th>
          <th class="title_width" :class="sortMethod === 'title_name' ? 'active arrow' : 'arrow'"  @click="sortRequest('title_name')">Title
            <span  v-if="sortDirAsc">&#8593;</span>
            <span v-else>&#8595</span>
          </th>
          <th class="author_width" :class="sortMethod === 'title_authors' ? 'active arrow' : 'arrow'"  @click="sortRequest('title_authors')">Authors
            <span  v-if="sortDirAsc">&#8593;</span>
            <span v-else>&#8595</span>
          </th>
          <th v-if="shared" class="requested_by_width" :class="sortMethod === 'requested_by' ? 'active arrow' : 'arrow'"  @click="sortRequest('requested_by')">User
            <span  v-if="sortDirAsc">&#8593;</span>
            <span v-else>&#8595</span>
          </th>
          <th class="data_width"  :class="sortMethod === 'updated_at' ? 'active arrow' : 'arrow'"  @click="sortRequest('created_at')">Date
            <span  v-if="sortDirAsc">&#8593;</span>
            <span v-else>&#8595</span>
          </th>
          <th class="status_width"  :class="sortMethod === 'status' ? 'active arrow' : 'arrow'" @click="sortRequest('status')">Status
            <span  v-if="sortDirAsc">&#8593;</span>
            <span v-else>&#8595</span>
          </th>
          <th class="cancel_width">Cancel</th>
        </tr>
        </thead>

        <tbody>
        <template v-for="request in  filteredRequests">
          <tr >
            <td class="title id_width text-center fz-12px">
              <a :href="'cc_requests/' + request.id">
                view details
              </a>
            </td>

            <td class="title title_width">
              <a v-if="request.attributes.title !== null" :href="'titles/' + request.attributes.title.slug">{{request.attributes.title.title}}</a>
            </td>

            <td class="title author_width">
              <template v-if="request.attributes.title !== null">
                <template v-for="(author, index) in request.attributes.title.authors">
                  <span v-if="index !== 0">, </span>
                  <a :href="'authors/' + author.id">{{author.display_name}}</a>
                </template>
              </template>
            </td>

            <td v-if="shared" class="requested_by_width">
              {{request.attributes.requested_by}}
            </td>

            <td :class="sortMethod === 'created_at'" >
              {{request.attributes.created_at_american}}
            </td>
            <td class="title status_width">
              {{request.attributes.status}}

            </td>
            <td  class="title cancel_width">
              <a v-if="!['Completed', 'Canceled'].includes(request.attributes.status) && request.attributes.requested_by_id == user_id"  class="print-none" @click="cancelRequest(request.id)"
                 href="javascript: void(0);"
                 v-confirm="{
                             html: true,
                             ok: dialog => cancelRequest(request.id),
                             customClass: 'my_modal',
                             okText: 'Cancel Request',
                             cancelText: 'Do Not Cancel',
                             message: `<h4>Cancel this request?</h4><hr><p>If canceled, Rightscenter will no longer investigate your request. ${additional_cancel_message}</p><hr>`,
                           }"
              >

                <img class="remove-icon" src="/cancel.svg" width="17px" height="17px" alt="Cancel">
              </a >

            </td>
          </tr>
        </template>
        <tr v-if="rights_check_request.length === 0">
          <td colspan="6">You haven't requested any rights checks yet</td>
        </tr>


        </tbody>


      </table>

    </div>

  </div>


</template>

<script>
    export default {
        name: "rights_check_request",
        data: function(){
          return{
            rights_check_request: [],
            sortMethod: null,
            sortDirAsc: true,
            includeCompleted: true,
            includeCompanyRequest: true,
            message: null,
          }
        },
        computed:  {
          filteredRequests: function () {
            if(this.includeCompleted && this.includeCompanyRequest) {
              return this.rights_check_request;
            }
            else if(this.includeCompleted == false && this.includeCompanyRequest == true) {
              return this.rights_check_request.filter(function (el) {
                return !['Completed', 'Canceled'].includes(el.attributes.status) ;
              });
            }
            else if(this.includeCompleted == true && this.includeCompanyRequest == false) {
              let user_id = this.user_id
              return this.rights_check_request.filter(function (el) {
                return (el.attributes.requested_by_id == user_id);
              });

            }
            else {
              let user_id = this.user_id
              return this.rights_check_request.filter(function (el) {
                return !['Completed', 'Canceled'].includes(el.attributes.status) && el.attributes.requested_by_id == user_id;
              });
            }

          }
        },
        props: ['shared', 'user_id', 'additional_cancel_message'],
        methods:{
            getRequest(){
                this.$http.get('/cc_requests.js')
                    .then(response => {
                        this.rights_check_request = response.body.data;
                    });

            },

            cancelRequest(id){
                this.$http.post('/cancel_request/' + id + '.js' )
                    .then(response => {
                      this.rights_check_request.filter(request => request.id === id)[0].attributes = response.body.data.attributes;
                      this.message = { class: 'notice', text: 'Your request has been canceled' };
                      window.location.reload();
                    } );
            },


            sortRequest(sortParam) {

                if (this.sortMethod === sortParam) {
                    this.sortDirAsc = !this.sortDirAsc;
                } else {
                    this.sortDirAsc = true;
                }
                this.sortMethod = sortParam;
                let sortDir = this.sortDirAsc;

                let request = this.rights_check_request;
                request.sort(function(a, b){
                    let fieldA;
                    let fieldB;

                    if (sortParam === 'created_at') {
                        fieldA = a.attributes[sortParam] == null ? '' : new Date(a.attributes[sortParam]);
                        fieldB = b.attributes[sortParam] ==  null ? '' : new Date(b.attributes[sortParam]);
                    } else if (sortParam === 'status') {
                        fieldA = a.attributes[sortParam] == null ? '' : a.attributes[sortParam].toLowerCase();
                        fieldB = b.attributes[sortParam] ==  null ? '' : b.attributes[sortParam].toLowerCase();
                    } else {
                        fieldA = a.attributes[sortParam] == null ? '' : a.attributes[sortParam];
                        fieldB = b.attributes[sortParam] ==  null ? '' : b.attributes[sortParam];
                    }

                    if (fieldA === fieldB) {
                        return 0;
                    }
                    else if (fieldA === '') {
                        return 1;
                    }
                    else if (fieldB === '') {
                        return -1;
                    }
                    else if (sortDir === true) {
                        return fieldA < fieldB ? -1 : 1;
                    }
                    else if (sortDir === false) {
                        return fieldA < fieldB ? 1 : -1;
                    }

                });
                this.rights_check_request = request;
            },
        },


        created:function () {
            this.getRequest()
        }
    }
</script>

<style scoped>

</style>

<template>
  <div class="main col-md-12" id="watch_list">

    <div :class="message.class" v-if="message">
      <a @click="message = null" class="close">[close]</a>
      <p>{{message.text}}</p>
      <div class="clear"></div>
    </div>

    <template v-if="watch_list.attributes">
      <p class="nav print-none"><a class="back-link" onclick="window.history.back();">&laquo; Back to previous</a></p>
      <div class="col-md-12">
        <template v-if="editTitle == true">

          <form class="form-inline mt-1">
            <div class="form-group mr-3 pt-2">
              <input type="text" class="watch-list-title-edit" v-model="watch_list.attributes.name">
            </div>
            <div class="btn-group" role="group">
              <button @click="updateWatchList({ name: watch_list.attributes.name })" type="button" class="btn btn-success">Save</button>
              <button @click="editTitle = false" type="button" class="btn btn-primary">Cancel</button>
            </div>
          </form>

        </template>
        <template v-else>
          <h2 class="title mt-3 mb-3 float-left">
            {{watch_list.attributes.name}}
            <img v-if="dragEnabled"
                 @click="showEdit('title')"
                 class="edit-icon"
                 src="/writing.svg"
                 width="20px"
                 alt="Edit title"
                 data-toggle="tooltip"
                 data-placement="top"
                 :data-original-title="'Edit title'">
          </h2>
        </template>

        <div class="export_watch_list_icon">
          <p>Export/Print</p>
          <a href="javascript: void(0);">
            <img src="/printer.svg" alt="Print page" title="Print page"
                 width="20px" onclick="window.print()"
                 class="print float-right print-none"
                 style="padding-top: 2px"
                 data-toggle="tooltip"
                 data-placement="top"
                 :data-original-title="'Print'"
            >
          </a>

          <a class="print float-right print-none" target="_blank" :href="'/' + (this.public ? 'public_' : '') + 'watch_lists/' + watch_list.id + '.pdf/'">
            <img src="/pdf.svg"
                 alt="Download pdf file"
                 title="Download pdf file"
                 height="20px" width="20px"
                 data-toggle="tooltip"
                 data-placement="top"
                 :data-original-title="'Export to PDF'">
          </a>
          <a class="print float-right print-none" target="_blank" :href="'/' + (this.public ? 'public_' : '') + 'watch_lists/' + watch_list.id + '.csv/'">
            <img src="/csv.svg" alt="Download csv file"
                 title="Download csv file"
                 height="20px"
                 width="20px"
                 data-toggle="tooltip"
                 data-placement="top"
                 :data-original-title="'Export to CSV'"
            >
          </a>
        </div>
      </div>

      <div class="col-md-12 float-left">
        <template v-if="editDescription == true">
          <div class="input-group mb-3">
            <textarea class="form-control" rows="5" v-model="watch_list.attributes.description"> </textarea>
          </div>

          <div class="btn-group mb-2 float-right" role="group">
            <button @click="updateWatchList({ description: watch_list.attributes.description })" type="button" class="btn btn-success">Save</button>
            <button @click="editDescription = false" type="button" class="btn btn-primary">Cancel</button>
          </div>
          
        </template>

        <template v-else>
          <p>
            <template v-if="watch_list.attributes.description">
              {{watch_list.attributes.description}}
            </template>
            <template v-else>
              Add description
            </template>

            <img v-if="dragEnabled"
                 @click="showEdit('description')"
                 class="edit-icon"
                 src="/writing.svg"
                 width="15px"
                 alt="Edit description"
                 data-toggle="tooltip"
                 data-placement="top"
                 :data-original-title="'Edit description'">
          </p>
        </template>



      </div>

      <template v-if="titles.length > 0 && watch_list.attributes.my_list === true">
        <div class="col-md-12 float-left">
          <div class="checkbox">
            <label class="boolean optional" for="watch_list_notifier">
              <input  :checked="watch_list.attributes.notifier" @click="methodPath()" class="boolean optional" type="checkbox" id="watch_list_notifier" >
              notify of changes to titles on this list
            </label>
          </div>
        </div>
      </template>

      <template v-if="titles.length > 0">
        <table class="table table-responsive watch_list_table" id="lists" width="100%">

          <thead>
          <tr>
            <th v-if="!dragEnabled" class="position-row print-none" :class="sortMethod == 'position' ? 'active arrow' : 'arrow'"
                @click="sortTitles('position')">
            </th>
            <th class="position-row screen-none"></th>

            <th class="title_table">
              <span class="print-none" :class="sortMethod == 'sort_title' ? 'active title-arrow' : 'title-arrow'" @click="sortTitles('sort_title')">
                Title
                <span  v-if="sortDirAsc">&#8593;</span>
                <span v-else>&#8595</span>
              </span>
              <span class="print-none" :class="sortMethod == 'primary_author' ? 'active arrow' : 'arrow'"
                    @click="sortTitles('primary_author')">
                Author(s)
                <span  v-if="sortDirAsc">&#8593;</span>
                <span v-else>&#8595</span>
              </span>
              <span class="print-span">
                Title & Author(s)
              </span>

            </th>

            <th :class="sortMethod == 'agent_display_name' ? 'agent_table active arrow' : 'agent_table arrow'" @click="sortTitles('agent_display_name')">
              Agent
              <span  v-if="sortDirAsc">&#8593;</span>
              <span v-else>&#8595</span>

            </th>
            <template v-if="titles.map(title => title.attributes.coagent_display_name).filter(x => x).length > 0">
              <th class="film_table" :class="sortMethod == 'coagent_display_name' ? 'active arrow' : 'arrow'" @click="sortTitles('coagent_display_name')">
                Film/TV Agent
                <span  v-if="sortDirAsc">&#8593;</span>
                <span v-else>&#8595</span>

              </th>
            </template>
            <template v-else>
              <th>Film/TV Agent</th>
            </template>

            <th class="avl_table" :class="sortMethod == 'rights_status' ? 'active arrow' : 'arrow'" @click="sortTitles('rights_status')">
              Availability
              <span  v-if="sortDirAsc">&#8593;</span>
              <span v-else>&#8595</span>

            </th>
            <template v-if="titles.map(title => title.attributes.rights_status_updated_at).filter(x => x).length > 0">
              <th class="rights_status_title"
                  :class="sortMethod == 'rights_status_updated_at' ? 'active arrow' : 'arrow'"
                  @click="sortTitles('rights_status_updated_at')">
                Last Update
                <span  v-if="sortDirAsc">&#8593;</span>
                <span v-else>&#8595</span>

              </th>
            </template>
            <template v-else>
              <th class="rights_status_title">Last Update</th>
            </template>

            <th v-if="dragEnabled" class="print-none" width="95px" :class="sortMethod == 'position' ? 'active arrow' : 'arrow'"
                @click="sortTitles('position')">
            </th>
          </tr>
          </thead>
          <draggable v-model="titles" @change="updateIndex" :disabled="!dragEnabled">
            <transition-group>
              <div v-for="title in titles" :key="title.attributes.position">
                <tr class="color_table">
                  <td v-if="!dragEnabled" class="position-row">
                    {{title.attributes.position}}
                  </td>
                  <td v-if="dragEnabled" class="position-row screen-none">
                    {{title.attributes.position}}
                  </td>
                  <td class="title_table">
                    <a :href="'/titles/' + title.attributes.slug">
                      {{getTitle(title)}}
                    </a>
                    <template v-if="title.attributes.series">
                      <br>
                      <a v-if="title.attributes.series"
                         class="series_link"
                         :href="'/titles?series=' + title.attributes.series">
                        <b>Series:</b> {{title.attributes.series}}
                      </a>
                    </template>
                    <br>
                    <b>by</b>
                    <template v-for="(author, index) in title.attributes.authors">
                      <a :href="'/authors/' + author.id">
                        {{author.display_name}}{{(index < title.attributes.authors.length - 1) ? ', ' : '' }}
                      </a>
                    </template>
                  </td>
                  <td class="agent_table">
                    <template v-if="title.attributes.agent">
                      {{title.attributes.agent}} <br>
                    </template>
                    <i>{{title.attributes.agent_company}}</i>
                  </td>
                  <td class="film_table">
                    <template v-if="title.attributes.coagent">
                      {{title.attributes.coagent}} <br>
                    </template>
                    <i>{{title.attributes.coagent_company}}</i>
                  </td>
                  <td class="svg_location">
                    <img
                            :src="'/' + title.attributes.rights_status + '.svg'"
                            width="25px"
                            alt=""
                            data-toggle="tooltip"
                            data-placement="top"
                            :data-original-title="title.attributes.rights_status"
                            class="print-none"
                    >
                    <span class="print-span">{{title.attributes.rights_status}}</span>
                  </td>
                  <td class="rights_status_title">
                    {{title.attributes.rights_status_updated_at}}
                  </td>
                  <td v-if="dragEnabled" style="width: 95px;" class="text-center print-none">
                    <template v-if="dragEnabled && editedPosition !== title.id">
                      <span @click="editPosition(title.id, title.attributes.position)" class="badge badge-dark position"
                        data-toggle="tooltip"
                        data-placement="top"
                        data-original-title="Update position"
                  >
                    {{title.attributes.position}}
                  </span>

                      <template v-if="watch_list.attributes.active_features.reminders">
                        <a v-if="title.attributes.reminders"
                           href="javascript: void(0);"
                           @click="removeReminder(title.attributes.reminders.id, title.id)"
                        >
                          <img data-toggle="tooltip"
                               data-placement="top"
                               :data-original-title=" 'Remind at ' + title.attributes.reminders.remind_at"
                               class="remove-icon print-none" src="/remove_date.svg" width="15px">
                        </a>

                        <datetime v-else
                                  v-model="date"
                                  type="date"
                                  :phrases="{ok: 'Set reminder', cancel: 'Exit'}"
                                  @input="setReminder(title.id)"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  :data-original-title=" 'Set a reminder'"
                        >
                        </datetime>

                      </template>

                      <a :id="'remove-title' + title.id" class="print-none" @click="removeTitle(title.id)"
                         href="javascript: void(0);"
                         v-confirm="{
                     html: true,
                     ok: dialog => removeTitle(title.id),
                     customClass: 'my_modal',
                     cancel: '',
                     okText: 'Delete',
                     cancelText: 'Cancel',
                     message: '<h4>Delete title from watch list?</h4><hr>'
                   }"
                      >

                        <img class="remove-icon"
                             src="/delete.svg"
                             width="15px"
                             alt="Remove from list"
                             data-toggle="tooltip"
                             data-placement="top"
                             :data-original-title="'Delete'">
                      </a >
                    </template>
                    <template v-if="editedPosition === title.id">
                      <button
                              @click="updatePosition"
                              class="btn btn-success position-btn"
                      >
                        &#10003;
                      </button>
                      <input min="1" :max="titles.length" type="number" v-model="new_position" class="form-control position-input">
                      <button
                              class="btn btn-dark position-btn"
                              @click="closeEdit()"
                      >
                        x
                      </button>
                    </template>
                    <template v-if="watch_list.attributes.public == true && !watch_list.attributes.my_list">
                      {{title.attributes.position}}
                    </template>
                  </td>
                </tr>
                <template v-if="title.attributes.notes">
                  <tr>
                    <td class="screen-none"></td>
                    <td>Rightscenter Note:</td>
                    <td colspan="5">
                      {{title.attributes.notes}}
                    </td>
                  </tr>
                </template>
                <tr>
                  <td v-if="title.attributes.note" class="screen-none"></td>
                  <td :class="title.attributes.note? '' : 'false_note' ">Note:</td>
                  <template v-if="title.attributes.note">
                    <td colspan="4">
                      {{title.attributes.note.body}}
                    </td>
                    <td class="align-middle text-right print-none" colspan="2">
                      <template v-if="watch_list.attributes.public != true || watch_list.attributes.my_list">
                        <a
                                class="mr-1"
                                :href="'/watch_lists/' + watch_list.id + '/title_notes/' + title.attributes.note.id + '/edit'"
                        >
                          <img src="/writing.svg"
                               width="15px"
                               alt=""
                               data-toggle="tooltip"
                               data-placement="top"
                               :data-original-title="'Edit'">
                        </a>

                        <a @click="deleteTitleNote(title.attributes.note.id, title.id)"
                           class=""
                           href="javascript: void(0);"
                        >
                          <img src="/delete.svg"
                               width="15px"
                               alt=""
                               data-toggle="tooltip"
                               data-placement="top"
                               :data-original-title="'Delete'">
                        </a >
                      </template>
                    </td>
                  </template>
                  <template v-else>
                    <td class="align-middle print-none" colspan="5">
                      <a v-if="dragEnabled" :href="'/watch_lists/' + watch_list.id + '/title_notes/new?title_id=' + title.id">Add note</a>
                    </td>
                  </template>
                </tr>
              </div>
            </transition-group>
          </draggable>
        </table>
      </template>

      <template v-else>
        <div class="row">
          <div class="col-md-12">
            <h4 class="mt-4"> {{ public ? 'Titles Not Found' : 'You have not added any titles to this list yet' }}</h4>
          </div>
        </div>
      </template>
    </template>

  </div>
</template>

<script>
  import draggable from 'vuedraggable';

  export default {
    data: function () {
      return {
        watch_list: {},
        titles: [],
        message: null,
        sortMethod: null,
        sortDirAsc: true,
        date: '',
        editDescription: false,
        editTitle: false,
        editedPosition: '',
        new_position: ''
      }
    },
    props: ['public'],
    methods: {
      showEdit(field) {
        $('[data-toggle="tooltip"]').tooltip('hide');
        if(field == 'description') {
          this.editDescription = true;
        } else  if (field == 'title') {
          this.editTitle = true;
        }
      },
      cancelEditTitle() {
        $('[data-toggle="tooltip"]').tooltip('hide');
        this.editTitle = false;
      },
      updateWatchList(params) {
        if(params.description) {
          this.editDescription = false;
        } else if(params.name) {
          this.editTitle = false;
        }

        this.$http.patch('/watch_lists/' + this.watch_list.id + '.js', { watch_list:
            params})
          .then(response => {
          })
          .catch((response) => this.failed(response.body.data))
      },
      methodPath(){
        this.$http.patch('/watch_lists/' + this.watch_list.id + '.js', { watch_list: {notifier: !this.watch_list.attributes.notifier}})
          .then(response => {
            this.watch_list.attributes.notifier = !this.watch_list.attributes.notifier;
          })
          .catch((response) => this.failed(response.body.data))
      },
      failed(data) {
        this.message = { class: 'error', text: data }
      },
      sortTitles(sortParam) {
        if (this.sortMethod === sortParam && this.sortDirAsc === true) {
          this.sortDirAsc = false;
        } else if(this.sortMethod === sortParam && this.sortDirAsc === false) {
          this.sortDirAsc = true;
          this.sortMethod = 'position';
          sortParam = 'position';
        } else {
          this.sortMethod = sortParam;
        }

        let sortDir = this.sortDirAsc;
        let titles = this.titles;
        titles.sort(function(a, b){
          let fieldA;
          let fieldB;
          if (sortParam === 'rights_status_updated_at') {
            fieldA = a.attributes[sortParam] == null ? '' : new Date(a.attributes[sortParam]);
            fieldB = b.attributes[sortParam] ==  null ? '' : new Date(b.attributes[sortParam]);
          } else if(sortParam == 'position') {
            fieldA = a.attributes[sortParam]
            fieldB = b.attributes[sortParam]
          }
          else {
            fieldA = a.attributes[sortParam] == null ? '' : a.attributes[sortParam].toLowerCase();
            fieldB = b.attributes[sortParam] ==  null ? '' : b.attributes[sortParam].toLowerCase();
          }

          if (fieldA === fieldB) {
            return 0;
          }
          else if (fieldA === '') {
            return 1;
          }
          else if (fieldB === '') {
            return -1;
          }
          else if (sortDir === true) {
            return fieldA < fieldB ? -1 : 1;
          }
          else if (sortDir === false) {
            return fieldA < fieldB ? 1 : -1;
          }

        });
        this.titles = titles;
      },
      getWatchList() {
        let url = window.location.pathname;
        let id = url.substring(url.lastIndexOf('/') + 1);
        this.$http.get('/' + (this.public ? 'public_' : '') + 'watch_lists/' + id + '.js')
      .then(response => {
          this.watch_list = response.body.data;
          this.titles = response.body.included;
          this.sortTitles('position');
        })
          .catch((response) => this.failed(response.body.data));
      },
      getTitle(title) {
        if(title.attributes.short_story) {
          return '"' + title.attributes.short_story + '"' + ' from ' + title.attributes.title;
        } else {
          return title.attributes.title;
        }
      },
      deleteTitleNote(id, title_id) {
        this.$http.delete('/watch_lists/' + this.watch_list.id + '/title_notes/' + id + '.js')
          .then(response => {
            this.titles.filter(title => title.id === title_id)[0].attributes.note = null;
            $('[data-toggle="tooltip"]').tooltip('hide');
          })
          .catch((response) => this.failed(response.body.data));
      },
      removeTitle(id) {
        this.$http.delete('/' + this.watch_list.id + '/titles/' + id + '.js')
          .then(response => {
            let titles = this.titles.filter(title => title.id !== id);
            this.titles = titles;
            $('[data-toggle="tooltip"]').tooltip('hide');
          })
          .catch((response) => this.failed(response.body.data));
      },
      setReminder(title_id) {
        if(this.date != '' && this.correctDate()) {
          this.$http.post('/title_reminders.js',
            { title_reminder: { remind_at: this.date, title_id: title_id, watch_list_id: this.watch_list.id }} )
            .then(response => this.titles.filter(title => title.id === title_id)[0].attributes.reminders = response.body.data)
            .catch((response) => this.failed(response.body.data));
          this.date = '';
        } else if(this.date != '') {
          swal("Selected date is invalid", 'Please choose a date in the future.', "error");
          this.date = '';
        }

      },
      correctDate() {
        let d1 = new Date(this.date);
        let d2 = new Date(this.watch_list.attributes.min_reminder_date);
        return d1 >= d2;
      },
      removeReminder(id, title_id) {
        this.$http.delete('/title_reminders/' + id + '.js')
          .then(response => {
            $('[data-toggle="tooltip"]').tooltip('hide');
            this.titles.filter(title => title.id === title_id)[0].attributes.reminders = null;
          })
          .catch((response) => this.failed(response.body.data));
      },
      editPosition(title_id, position) {
        $('[data-toggle="tooltip"]').tooltip('hide');
        this.editedPosition = title_id;
        this.new_position = position;
      },
      closeEdit() {
        this.new_position = '';
        this.editedPosition = '';
        $('[data-toggle="tooltip"]').tooltip('hide');
      },
      updatePosition(){
        this.$http.patch('/watch_lists/title_watch_list.js', { watch_list_id: this.watch_list.id, id: this.editedPosition, position: this.new_position})
                .then(response => {
                  this.closeEdit();
                  this.sortDirAsc = false;
                  this.getWatchList();
                })
                .catch((response) => this.failed(response.body.data))
      },
      updateIndex: function(e) {
        this.$http.patch('/watch_lists/title_watch_list.js', { watch_list_id: this.watch_list.id, id: e.moved.element.id, position: e.moved.newIndex + 1})
                .then(response => {
                  this.sortDirAsc = false;
                  this.getWatchList();
                })
                .catch((response) => this.failed(response.body.data))
      }
    },
    created: function () {
      this.getWatchList();
    },
    updated: function () {
      $('[data-toggle="tooltip"]').tooltip();
    },
    components: {
      draggable,
    },
    computed: {
      dragEnabled() {
        return (this.watch_list.attributes.public != true || this.watch_list.attributes.my_list)
      }
    }
  }

</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.authors),function(author){return (_vm.authors && _vm.authors.length > 0)?_c('ul',{staticClass:"list-group list-group-flush"},[_c('li',{staticClass:"list-group-item"},[_c('a',{attrs:{"href":'/authors/' + author.id}},[_vm._v("\n        "+_vm._s(author.attributes.display_name)+"\n        ("+_vm._s(author.attributes.numbers_of_titles)+" "+_vm._s(author.attributes.numbers_of_titles === 1 ? 'record' : 'records')+")\n      ")]),_vm._v(" "),_c('a',{directives:[{name:"confirm",rawName:"v-confirm",value:({

          ok: function (dialog) { return _vm.removeFromFavorite(author.id); },
          html: true,
          customClass: 'my_modal',
          okText: 'Remove from favorite',
          cancelText: 'Cancel',
          message: '<p>Are you sure you want to remove the author from your favorites?</p><hr>'
         }),expression:"{\n\n          ok: dialog => removeFromFavorite(author.id),\n          html: true,\n          customClass: 'my_modal',\n          okText: 'Remove from favorite',\n          cancelText: 'Cancel',\n          message: '<p>Are you sure you want to remove the author from your favorites?</p><hr>'\n         }"}],staticClass:"print-none float-right",attrs:{"href":"javascript: void(0);"}},[_c('img',{staticClass:"remove-icon",attrs:{"src":"/cancel.svg","width":"17px","height":"17px","alt":"Cancel"}})])])]):_vm._e()}),_vm._v(" "),(_vm.authors.length === 0)?_c('h4',{staticClass:"mt-4"},[_vm._v("You have not chosen any Favorite Authors yet")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>

  <div>
    <ul v-if="authors && authors.length > 0" v-for="author in authors"  class="list-group list-group-flush">
      <li class="list-group-item">
        <a :href="'/authors/' + author.id ">
          {{author.attributes.display_name}}
          ({{author.attributes.numbers_of_titles}} {{author.attributes.numbers_of_titles === 1 ? 'record' : 'records'}})
        </a>
        <a class="print-none float-right"
           href="javascript: void(0);"
           v-confirm="{

            ok: dialog => removeFromFavorite(author.id),
            html: true,
            customClass: 'my_modal',
            okText: 'Remove from favorite',
            cancelText: 'Cancel',
            message: '<p>Are you sure you want to remove the author from your favorites?</p><hr>'
           }"
        >

          <img class="remove-icon" src="/cancel.svg" width="17px" height="17px" alt="Cancel">
        </a >
      </li>
    </ul>
    <h4 v-if="authors.length === 0" class="mt-4">You have not chosen any Favorite Authors yet</h4>
  </div>

</template>

<script>
  export default {
    data: function () {
      return {
        message: null,
        authors: []
      }
    },
    methods: {
      failed(data) {
        this.message = { class: 'error', text: data }
      },
      removeFromFavorite(id) {
        this.$http.delete('/favorite_authors/' + id + '.js')
          .then(response => {
            this.authors = this.authors.filter(author => author.id !== id);
          })
          .catch((response) => this.failed(response.body.data));
      },
      getAuthors() {
        this.$http.get('/favorite_authors.js')
          .then(response => this.authors = response.body.data);
      },
    },
    created: function () {
      this.getAuthors();
    },
    updated: function () {

    },

  }
</script>

import Vue from 'vue/dist/vue.esm'
import VueResource from 'vue-resource';
import VuejsDialog from 'vuejs-dialog';
import { Datetime } from 'vue-datetime';
import Swal from 'sweetalert';

import Favorite from '../favorite.vue'
import Author from '../author.vue'
import WatchLists from '../watch_lists'
import WatchList from '../watch_list'
import  RightsCheckRequest from '../rights_check_request'
import  FavoriteAuthors from '../favorite_authors'
import TitleReminder from '../title_reminder'
import Titles from '../titles'

Vue.use(VueResource);
Vue.use(VuejsDialog);
Vue.component('datetime', Datetime);
Vue.component('favorite', Favorite);
Vue.component('swal', Swal);
Vue.component('author', Author);
Vue.component('watch_lists', WatchLists);
Vue.component('watch_list', WatchList);
Vue.component('rights_check_request', RightsCheckRequest);
Vue.component('favorite_authors', FavoriteAuthors);
Vue.component('title_reminder', TitleReminder);
Vue.component('titles', Titles)

Vue.http.interceptors.push(function(request, next) {
  request.headers.set('X-CSRF-TOKEN', document.querySelectorAll('meta[name="csrf-token"]')[0].content);
});

document.addEventListener('DOMContentLoaded', () => {
  if (document.body.querySelector('[data-behavior="vue"]')) {
    const app = new Vue({
      el: '[data-behavior="vue"]',
    });
  }
  if (document.body.querySelector('[data-behavior="vue_second"]')) {
    const app_second = new Vue({
      el: '[data-behavior="vue_second"]',
    });
    $('[data-toggle="tooltip"]').tooltip();
  }
});

<template>
  <div id="favorite" class="">

    <template>
      <div id="messages">
        <div :class="message.class" v-if="message">
          <a @click="message = null" class="close">[close]</a>
          <p>{{message.text}}</p>
          <div class="clear"></div>
        </div>
      </div>


      <template v-show="addToNew">

        <div v-show="addToNew" class="form-group">
          <input type="text" class="form-control" v-model="newListName" placeholder="Create new watch list" >
          <button type="button" class="btn btn-danger btnCancel" @click="cancelMethod()">Cancel</button>
          <button type="button" class="btn btn-success btnCreate" @click="addToNewList()">Create</button>
          <div v-if="errors.addToNew" class="invalid">
            {{errors.addToNew}}
          </div>
        </div>

      </template>

      <template v-show="addToNew">
        <div v-show="!addToNew" @click="targetMethod()" class="form-group">
          <select id="lists"
                  data-style="btn-primary"
                  v-model="selectedList" v-if="lists"


          >
            <option value="">Add to a Watch List</option>
            <template v-for="list in lists" >
              <option :value="list" :disabled="inList(list)" >{{list.name}}{{inList(list) ? ' (added)' : ''}}</option>
            </template>
            <option data-divider="true"></option>
            <option value="CreateNewWatchList">Create new</option>
          </select>
          <div v-if="errors.addToExist" class="invalid">
            {{errors.addToExist}}
          </div>
        </div>
      </template>


    </template>

  </div>
</template>

<script>

export default {
  data: function () {
    return {
      lists: [],
      addToNew: false,
      selectedList: '',
      newListName: '',
      message: null,
      titleId: null,
      errors: {
        addToNew: null,
        addToExist: null,

      },
    }
  },
  watch: {
    selectedList: function() {
      if(this.selectedList && this.selectedList !== 'CreateNewWatchList' && Object.entries(this.selectedList).length !== 0) {
        let titles = [];
        if(this.selectedList.titles) {
          titles = this.selectedList.titles;
        }
        titles.push(this.titleId);
        this.$http.patch('/watch_lists/' + this.selectedList.id + '.js',
          { watch_list: { title_ids: titles } })
          .then(response => this.addToExistSuccess(response.body.data.attributes))
          .catch((response) => this.failed(response.body.data));
      }
    },
  },
  methods: {
    addToNewList() {
      if(this.newListName.length > 0) {
        this.$http.post('/watch_lists.js',
          { watch_list: { name: this.newListName, title_ids: [this.titleId] } })
          .then(response => this.addSuccess(response.body.data.attributes))
          .catch((response) => this.failed(response.body.data));
      } else {
        this.errors.addToNew = 'This is required field'
      }
    },
    addSuccess(data) {
      this.reset();
      this.message = { class: 'notice', text: 'Title successfully added to the new watch list' };
      this.lists.push(data);
    },
    addToExistSuccess(data) {
      this.reset();
      this.message = { class: 'notice', text: 'Title successfully added to the selected watch list' };
      let list = this.lists.filter(function (el) {
        return el.id == data.id;
      })[0];
      list.titles = data.titles;
    },
    reset() {
      this.addToNew = false;
      this.selectedList = '';
      this.newListName = '';
    },
    failed(data) {
      this.message = { class: 'error', text: data }
    },
    inList(list) {
      return list.titles.includes(this.titleId);
    },
    getLists() {
      this.$http.get('/watch_lists.js')
        .then(response => this.lists = response.body.data.reduce((arr, item) => (arr.push(item.attributes), arr) ,[]));
    },
    targetMethod(){
      if(this.selectedList === "CreateNewWatchList"){
        this.addToNew = true;
      }

    },
    cancelMethod(){
      this.addToNew = false;
      this.selectedList = '';
    }
  },
  created: function () {
    this.getLists();
    this.titleId = parseInt($('#titleId').val());
  },
  updated: function () {
    $('#lists').selectpicker('refresh');
  },

}
</script>

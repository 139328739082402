<template>
  <div>
    <p class="nav"><a class="back-link" onclick="window.history.back();">&laquo; Back to previous</a></p>

    <div class="float-left mr-2">
      <template v-if="author.attributes && author.attributes.favorite === true">
        <a @click="removeFromFavorite"
           href="javascript: void(0);"
           class="remove_from_favorite"
           data-toggle="tooltip"
           data-placement="top"
           data-original-title="Remove from favorite"
        >
          <img src="/not_favorite.svg" width="25px" alt="">
        </a>
      </template>
      <template  v-else>
        <a @click="addToFavorite"
           href="javascript: void(0);"
           class="add_to_favorite"
           data-toggle="tooltip"
           data-placement="top"
           data-original-title="Add to favorite"
        >
          <img src="/favorite.svg" width="25px" alt="">
        </a>
      </template>
    </div>

    <h2 class="title" v-if="author && author.attributes">
      {{ author.attributes.display_name }} <span class="records_favorite_author">({{author.attributes.numbers_of_titles}} {{author.attributes.numbers_of_titles === 1 ? 'record' : 'records'}})</span>
    </h2>

    <table class="table table-striped table-responsive-sm">
      <thead>
      <tr>

        <th :class="sortingMethods.title.active == true ? 'active arrow' : 'arrow'" @click="sortTitles('title')" >Title
          <span  v-if="sortingMethods.title.direction == 'asc'">&#8593;</span>
          <span v-else>&#8595</span>
        </th>

        <template v-if="titles.map(title => title.attributes.series).filter(x => x).length > 0">
          <th width="150px" :class="sortingMethods.series.active == true ? 'active arrow' : 'arrow'" @click="sortTitles('series')">Series
            <span v-if="sortingMethods.series.direction == 'asc'">&#8593;</span>
            <span v-else>&#8595</span>
          </th>
        </template>

        <template v-else>
          <th width="150px">Series</th>
        </template>

        <th width="" :class="sortingMethods.status.active == true ? 'active arrow' : 'arrow'" @click="sortTitles('status')">Status
          <span v-if="sortingMethods.status.direction == 'asc'">&#8593;</span>
          <span v-else>&#8595</span>
        </th>
        <th width="150px">Last update</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="title in titles">
        <td class="title">
          <a class="title-link" :href="'/titles/' + title.attributes.slug">
            <span class="title">{{title.attributes.title}}</span>
            <span class="subtitle">
            {{title.attributes.short_story == null ? title.attributes.subtitle : title.attributes.short_story}}
          </span>
          </a>
        </td>
        <td>{{title.attributes.series}}</td>
        <td class="">{{title.attributes.rights_status}}</td>
        <td class="">{{title.attributes.right_status_updated_at}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    data: function () {
      return {
        author: {},
        titles: [],
        message: null,
        sortingMethods: {
          title: { active: false, direction: 'asc' },
          series: { active: false, direction: 'asc' },
          status: { active: false, direction: 'asc' },
        }
      }
    },
    methods: {
      failed(data) {
        this.message = { class: 'error', text: data }
      },
      addToFavorite() {

        this.$http.post('/favorite_authors', { id: this.author.id })
        .then(response => {
          $('.add_to_favorite').tooltip('hide');
          this.author.attributes.favorite = true;
        })
        .catch((response) => this.failed(response.body.data));
      },
      removeFromFavorite() {
        this.$http.delete('/favorite_authors/' + this.author.id + '.js')
        .then(response => {
          $('.remove_form_favorite').tooltip('hide');
          this.author.attributes.favorite = false;
        })
        .catch((response) => this.failed(response.body.data));
      },
      sortTitles(param) {
        this.changeSort(this.sortingMethods[param]);
        let sortSeries = this.sortingMethods.series;
        let sortTitle = this.sortingMethods.title;
        let sortStatus = this.sortingMethods.status;
        let titles = this.titles;
        titles.sort(function(a, b){
          let seriesA = a.attributes.series == null ? '' : a.attributes.series.toLowerCase();
          let seriesB = b.attributes.series ==  null ? '' : b.attributes.series.toLowerCase();
          let titleA = a.attributes.title.toLowerCase(), titleB = b.attributes.title.toLowerCase();
          let statusA = a.attributes.rights_status.toLowerCase(), statusB = b.attributes.rights_status.toLowerCase();

          if (sortSeries.active === true) {
            if (seriesA === seriesB) {
              return 0;
            }
            else if (seriesA === '') {
              return 1;
            }
            else if (seriesB === '') {
              return -1;
            }
            else if (sortSeries.direction === 'asc') {
              return seriesA < seriesB ? -1 : 1;
            }
            else if (sortSeries.direction === 'desc') {
              return seriesA < seriesB ? 1 : -1;
            }
          }

          if (sortStatus.direction === 'asc' && sortStatus.active === true) {
            if (statusA < statusB) return -1;
            if (statusA > statusB) return 1;
          } else if (sortStatus.direction === 'desc' && sortStatus.active === true) {
            if (statusA < statusB) return 1;
            if (statusA > statusB) return -1;
          }

          if (sortTitle.direction === 'asc' && sortTitle.active === true) {
            if (titleA < titleB) return -1;
            if (titleA > titleB) return 1;
          } else if (sortTitle.direction === 'desc' && sortTitle.active === true) {
            if (titleA < titleB) return 1;
            if (titleA > titleB) return -1;
          }

        });
        this.titles = titles;
      },
      changeSort(method) {
        if (method.active == false) {
          method.active = true;
        } else if (method.active ==  true && method.direction == 'asc') {
          method.direction = 'desc';
        } else if (method.active ==  true && method.direction == 'desc'){
          method.active = false;
          method.direction = 'asc';
        }
      },
      getAuthor() {
        let url = window.location.pathname;
        let id = url.substring(url.lastIndexOf('/') + 1);
        this.$http.get('/authors/' + id + '.js')
      .then(response => {
          this.author = response.body.data;
          this.titles = response.body.included.filter(title => title.attributes.published === true);
        })
          .catch((response) => this.failed(response.body.data));
      },
    },
    created: function () {
      this.getAuthor();
    },
    mounted: function () {
      $('[data-toggle="tooltip"]').tooltip();
    }
  }
</script>
